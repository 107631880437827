import { apiClient } from "../../components/api/apiClient";
import { TelemetryDashboardType } from "./enums/TelemetryDashboardType.enum";
import { TelemetryPointsConfig } from "./telemetryConfig";

export interface EquipmentListDto {
  equipment: EquipmentDto[];
  sites: EquipmentSiteDto[];
  equipmentTypes: EquipmentTypeDto[];
}

export interface EquipmentDto {
  id: string;
  name: string;
  assetTypeId: string;
  siteId: string;
  telemetryId: string;
  iconUrl: string;
  assetType: AssetTypeDto;
}

export interface AssetTypeDto {
  description: string;
  iconUrl: string;
  mobilityType: string;
  name: string;
  id: string;
}

export interface EquipmentSiteDto {
  id: string;
  displayName: string;
  fullAddress: string;
  geoLocation: {
    latitude: number;
    longitude: number;
  };
}

export interface TelemetryTagDto {
  id: string;
  tagId: string;
  label: string;
  tagValue: string;
  formattedValue: string;
  backgroundColor: string;
  textColor: string;
  lastUpdateTs: number;
}

export interface EquipmentTelemetrySummaryDto {
  equipment: EquipmentDto;
  tags: TelemetryTagDto[];
  lastUpdatedAt: string;
}

export interface EquipmentTypeDto {
  id: string;
  name: string;
  description: string;
  type: number;
  iconUrl: string;
}

export interface EquipmentTelemetryDto {
  equipment: EquipmentDto;
  tags: TelemetryTagDto[];
  lastUpdatedAt: string;
  dashboards: DashboardDto[];
}

export interface DashboardDto {
  id: string;
  displayName: string;
  type: TelemetryDashboardType;
  displaySettings: TelemetryPointsConfig;
}

export interface EquipmentTags {
  tags: TelemetryTagDto[];
  lastUpdatedAt: string;
}

export const equipmentsApi = {
  async getEquipmentList(): Promise<EquipmentListDto> {
    const response = await apiClient.get("/equipment");
    return response.data;
  },

  async getEquipmentTelemetrySummary(
    telemetryId: string
  ): Promise<EquipmentTelemetrySummaryDto> {
    const response = await apiClient.get(`/telemetry/${telemetryId}/summary`);
    return response.data;
  },

  async getFullEquipmentTelemetry(
    telemetryId: string
  ): Promise<EquipmentTelemetryDto> {
    const response = await apiClient.get(`/telemetry/${telemetryId}`);
    return response.data;
  },

  async getEquipmentTags(telemetryId: string): Promise<EquipmentTags> {
    const response = await apiClient.get(`/telemetry/${telemetryId}/tags`);
    return response.data;
  },

  async getEquipmentById(equipmentId: string): Promise<any> {
    const response = await apiClient.get(`/equipment/${equipmentId}`);
    return response.data;
  },
};
