import { Loading } from "@carbon/react";
import { useEffect, useRef, useState } from "react";
import { DiagramValueProps, DiagramValues } from "./DiagramValues";
import { EquipmentTags, TelemetryTagDto, equipmentsApi } from "./equipmentsApi";
import { TelemetryPointsConfig } from "./telemetryConfig";

export const TAGS_REFRESH_INTERVAL_MS = 5000;
export interface ProcessFlowDiagramProps {
  telemetryId: string;
  telemetryPointsConfig: TelemetryPointsConfig | undefined;
}

export const ProcessFlowDiagram = ({
  telemetryId,
  telemetryPointsConfig,
}: ProcessFlowDiagramProps) => {
  if (!telemetryPointsConfig) {
    return <div>Telemetry points not available</div>;
  }

  const { diagramSettings, telemetryPoints } = telemetryPointsConfig;
  const { fileUrl } = diagramSettings;

  const boardBackgroundImageUrl = fileUrl;

  const parentContainerRef: any = useRef();
  const imageContainerRef: any = useRef();

  const [imageLoadedData, setImageLoadedData] = useState<any>(null);
  const [telemetryTags, setTelemetryTags] = useState<EquipmentTags | null>(
    null
  );

  const handleOnBackgroundImageLoad = (eventData: any) => {
    const originalHeight = eventData?.nativeEvent?.srcElement?.naturalHeight;
    const originalWidth = eventData?.nativeEvent?.srcElement?.naturalWidth;
    const originalAspectRatio = originalWidth / originalHeight;

    const renderedWidth = parentContainerRef?.current?.offsetWidth;
    const renderedHeight = renderedWidth / originalAspectRatio;
    const renderedAspectRatio = renderedWidth / renderedHeight;

    setImageLoadedData({
      loaded: true,
      renderedDimensions: {
        width: renderedWidth,
        height: renderedHeight,
        aspectRatio: renderedAspectRatio,
        widthScalingFactor: originalWidth / renderedWidth,
        heightScalingFactor: originalHeight / renderedHeight,
      },
      originalDimensions: {
        width: originalWidth,
        height: originalHeight,
        aspectRatio: originalAspectRatio,
      },
    });
  };

  const handleOnBackgroundImageError = () => {
    setImageLoadedData({ loaded: false, error: true });
  };

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (parentContainerRef.current) {
      setDimensions({
        width: parentContainerRef.current.offsetWidth,
        height: parentContainerRef.current.offsetHeight,
      });
    }

    getTelemetryData();

    const intervalId = setInterval(getTelemetryData, TAGS_REFRESH_INTERVAL_MS);

    return () => clearInterval(intervalId);
  }, []);

  const getTelemetryData = async () => {
    try {
      const data = await equipmentsApi.getEquipmentTags(telemetryId);
      setTelemetryTags(data);
    } catch {
      setTelemetryTags(null);
    }
  };

  const getTelemetryPointTopOffsetForKey = (key: string): number => {
    const heightScalingFactor =
      imageLoadedData?.renderedDimensions?.heightScalingFactor;

    if (telemetryPoints) {
      const telemetryPoint = telemetryPoints[key];
      return telemetryPoint ? telemetryPoint.y / heightScalingFactor : 0;
    }

    return 0;
  };

  const getTelemetryPointLeftOffsetForKey = (key: string): number => {
    const widthScalingFactor =
      imageLoadedData?.renderedDimensions?.widthScalingFactor;

    if (telemetryPoints) {
      const telemetryPoint = telemetryPoints[key];
      return telemetryPoint ? telemetryPoint.x / widthScalingFactor : 0;
    }

    return 0;
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      ref={parentContainerRef}
    >
      {dimensions && dimensions.height && dimensions.width ? (
        <div style={{ position: "relative" }}>
          <img
            ref={imageContainerRef}
            src={boardBackgroundImageUrl}
            style={{
              width: imageLoadedData?.renderedDimensions?.width,
              height: imageLoadedData?.renderedDimensions?.height,
              position: "relative",
              display:
                imageLoadedData && imageLoadedData.loaded ? "block" : "none",
            }}
            onLoad={handleOnBackgroundImageLoad}
            onError={handleOnBackgroundImageError}
          />
          {/* telemetry data container */}
          <div
            style={{
              position: "absolute",
              top: 0,
              width: imageLoadedData?.renderedDimensions?.width,
              height: imageLoadedData?.renderedDimensions?.height,
              backgroundColor: "transparent",
            }}
          >
            {telemetryTags &&
              telemetryTags.tags.length > 0 &&
              telemetryTags.tags.map((tag: TelemetryTagDto) => {
                const top = getTelemetryPointTopOffsetForKey(tag.tagId);
                const left = getTelemetryPointLeftOffsetForKey(tag.tagId);

                if (top === 0 && left === 0) {
                  return null;
                }

                const alternateNames =
                  telemetryPointsConfig?.telemetryPointLabels?.[tag.tagId];

                const tooltipLabel = alternateNames
                  ? `${tag.tagId} ${alternateNames.join(", ")}`
                  : tag.tagId;

                const diagramValuesProps: DiagramValueProps = {
                  heightScalingFactor:
                    imageLoadedData?.renderedDimensions?.heightScalingFactor ||
                    1,
                  widthScalingFactor:
                    imageLoadedData?.renderedDimensions?.widthScalingFactor ||
                    1,
                  top,
                  left,
                  tooltipLabel,
                  tagId: tag.tagId,
                  tag: {
                    backgroundColor: tag.backgroundColor,
                    textColor: tag.textColor,
                    tagValue: tag.tagValue,
                  },
                };

                return (
                  <DiagramValues
                    key={`value_${tag.tagId}`}
                    {...diagramValuesProps}
                  />
                );
              })}
          </div>
        </div>
      ) : (
        <Loading color={"grey"} withOverlay={false} />
      )}
    </div>
  );
};
