import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = (dateInString: string): string => {
  return dayjs(dateInString).tz().format("DD MMMM YYYY");
};

export const formatToMonthYear = (dateInString: string): string => {
  return dayjs(dateInString).tz().format("MM/YYYY");
};

export const formatDateWithTime = (dateInString: string | number): string => {
  return dayjs(dateInString).tz().format("DD MMMM YYYY @ HH:mm");
};

export const getUtcNow = (): string => {
  return dayjs().toISOString();
};

export const getTimestampInSeconds = (): number => {
  return Math.floor(Date.now() / 1000);
};
