import { Button } from "@carbon/react";
import { useEffect, useRef, useState } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import { useNavigate } from "react-router";
import "./customMarker.scss";
import {
  EquipmentDto,
  EquipmentSiteDto,
  EquipmentTelemetrySummaryDto,
  EquipmentTypeDto,
  equipmentsApi,
} from "./equipmentsApi";

interface CustomMarkerProps {
  data: {
    site: EquipmentSiteDto;
    equipmentType?: EquipmentTypeDto;
    equipment: EquipmentDto;
  };
}

const CustomMarker = ({ data }: CustomMarkerProps) => {
  const navigate = useNavigate();
  const [refReady, setRefReady] = useState<boolean>(false);
  const popupRef = useRef<any>(null);
  const map = useMap();
  const [showStatus, setShowStatus] = useState<boolean>(false);
  const [telemetrySummary, setTelemetrySummary] =
    useState<EquipmentTelemetrySummaryDto | null>();

  useEffect(() => {
    if (refReady) {
      map.openPopup(popupRef.current);
    }
    getTelemetrySummary();
  }, [refReady, map]);

  const getTelemetrySummary = async () => {
    try {
      const summary = await equipmentsApi.getEquipmentTelemetrySummary(
        data.equipment.telemetryId
      );
      setTelemetrySummary(summary);
    } catch (e) {
      setTelemetrySummary(null);
    }
  };

  return (
    <div
      onClick={() => {
        setShowStatus(!showStatus);
      }}
    >
      <Marker
        position={[
          data.site.geoLocation.longitude,
          data.site.geoLocation.latitude,
        ]}
      >
        <Popup
          ref={(r: any) => {
            popupRef.current = r;
            setRefReady(true);
          }}
          className="custom-popup"
          closeButton={false}
          autoClose={false}
          closeOnClick={false}
          closeOnEscapeKey={false}
        >
          <div className="cds--type-heading-01 popup-equipment-name">
            {data.equipment.name}
          </div>
          <div className="marker-equipment-details">
            <div>{data.site?.displayName}</div>
            <div>{data.equipmentType?.name}</div>
          </div>

          {showStatus ? (
            <div>
              {telemetrySummary &&
                telemetrySummary.tags.map((tag, index) => (
                  <div key={index} className="popup-status">
                    <span className="status-type">{tag.label}</span>
                    <span
                      className="status-condition"
                      style={{
                        backgroundColor: tag.backgroundColor,
                        color: tag.textColor,
                      }}
                    >
                      {tag.formattedValue || tag.tagValue}
                    </span>
                  </div>
                ))}

              <div className="popup-footer">
                <Button
                  kind="primary"
                  size="sm"
                  onClick={() =>
                    navigate(
                      `/monitoring/${data.equipment.id}/equipment-details`,
                      {
                        state: {
                          telemetryId: data.equipment.telemetryId,
                          equipmentId: data.equipment.id,
                          equipmentName: data.equipment.name,
                          iconUrl: data.equipment.iconUrl,
                          equipmentType: data.equipmentType?.name,
                        },
                      }
                    )
                  }
                >
                  More Details
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Popup>
      </Marker>
    </div>
  );
};

export default CustomMarker;
