import { Renew } from "@carbon/icons-react";
import { Button, ContentSwitcher, Loading, Stack, Switch } from "@carbon/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import PageTitle from "../../common/PageTitle";
import "../../common/scss/loading.scss";
import { EquipmentListCardView } from "./EquipmentListCardView";
import { EquipmentListMapView } from "./EquipmentListMapView";
import { EquipmentListDto, equipmentsApi } from "./equipmentsApi";
import "./monitoring.scss";

const CARD_VIEW_INDEX = 0;
const MAP_VIEW_INDEX = 1;
const MAP_VIEW_PATH = "/monitoring/map";
const CARD_VIEW_PATH = "/monitoring/card";

export const MonitoringHomePage = () => {
  const location = useLocation();
  const initialIndex = location.pathname.includes(MAP_VIEW_PATH)
    ? MAP_VIEW_INDEX
    : CARD_VIEW_INDEX;
  const [selectedView, setSelectedView] = useState(initialIndex);
  const [equipmentList, setEquipmentList] = useState<EquipmentListDto | null>(
    null
  );
  const [refreshFlag, setRefreshFlag] = useState(false);
  const navigate = useNavigate();

  const handleSwitchChange = (event: any) => {
    setSelectedView(event.index);
    if (event.index === CARD_VIEW_INDEX) {
      navigate(CARD_VIEW_PATH);
    } else {
      navigate(MAP_VIEW_PATH);
    }
  };

  useEffect(() => {
    if (location.pathname.includes(MAP_VIEW_PATH)) {
      setSelectedView(MAP_VIEW_INDEX);
    } else {
      setSelectedView(CARD_VIEW_INDEX);
    }
    getEquipmentList();
  }, [location.pathname]);

  const getEquipmentList = async () => {
    const equipmentList = await equipmentsApi.getEquipmentList();
    setEquipmentList(equipmentList);
  };

  const refreshEquipmentList = () => {
    getEquipmentList();
    setRefreshFlag(!refreshFlag);
  };

  return (
    <Stack>
      <PageTitle pageTitle={"Monitoring"} />
      <div className="switcher-container">
        <ContentSwitcher
          onChange={handleSwitchChange}
          selectedIndex={selectedView}
          size={"md"}
          className={"content-switcher"}
        >
          <Switch name="map" text="Card View" className="switch-selected" />
          <Switch name="two" text="Map View" className="switch-selected" />
        </ContentSwitcher>
      </div>

      <div className="monitoring-container">
        <div className="button-container">
          <Button
            kind="ghost"
            renderIcon={Renew}
            iconDescription="Refresh"
            hasIconOnly
            onClick={refreshEquipmentList}
          />
        </div>

        {equipmentList ? (
          selectedView === CARD_VIEW_INDEX ? (
            <EquipmentListCardView
              equipmentList={equipmentList}
              refreshFlag={refreshFlag}
            />
          ) : (
            <EquipmentListMapView equipmentList={equipmentList} />
          )
        ) : (
          <div className="loading-container">
            <Loading withOverlay={false} />
          </div>
        )}
      </div>
    </Stack>
  );
};
