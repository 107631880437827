export function PartnerLogo() {
  return (
    <div>
      <svg
        width="210"
        height="82"
        viewBox="0 0 210 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.4089 0.725892L26.6305 15.1367H32.5185L33.9906 12.8225H39.0375V15.1367H44.0843V0.725892H36.4089ZM35.8831 9.66688L39.0375 4.51266V9.66688H35.8831ZM69.1086 15.1367L67.7417 0.725892H60.0662L57.753 8.615L55.545 0.725892H47.7644L46.3975 15.1367H51.4444L52.1804 5.24898L54.9142 15.1367H60.0662L63.0102 5.24898L63.8514 15.1367H69.1086ZM81.4104 0.725892H71.4217V15.1367H76.5738V11.3499H80.5692C84.8801 11.3499 87.0881 9.14094 87.0881 5.9853C87.0881 2.82965 85.1955 0.725892 81.4104 0.725892ZM79.2024 8.08906H76.5738V4.1971H79.5178C80.9898 4.1971 81.8309 4.93341 81.8309 6.09048C81.8309 7.35274 80.8847 8.08906 79.2024 8.08906ZM97.6025 15.5574C103.385 15.5574 106.96 12.0862 106.96 7.77349C106.96 3.35559 103.28 0.199951 97.6025 0.199951C91.8196 0.199951 88.2447 3.46078 88.2447 7.77349C88.2447 12.0862 91.8196 15.5574 97.6025 15.5574ZM97.6025 11.4551C95.1842 11.4551 93.7122 9.98244 93.7122 7.87868C93.7122 5.77492 95.1842 4.40747 97.6025 4.40747C99.9157 4.40747 101.493 5.77492 101.493 7.87868C101.493 9.98244 99.9157 11.4551 97.6025 11.4551ZM113.9 10.9291V0.725892H108.748V15.1367H121.996V10.9291H113.9Z"
          fill="#ED0D05"
        />
        <path
          d="M136.19 40.5922C139.345 40.5922 141.027 39.2247 141.027 37.0158C141.027 34.8068 139.555 33.5446 136.716 33.5446H131.249V40.6974H136.19V40.5922ZM140.186 26.9177C147.23 26.9177 150.805 30.9149 150.805 36.7002C150.805 42.4856 146.705 46.5879 138.714 46.5879H131.249V53.5303H121.68V26.9177H140.186ZM106.224 35.2276L100.862 53.6355H91.2939L86.247 35.438L84.8801 53.6355H75.5224L78.1509 27.0229H92.4505L96.5511 41.6441L100.757 27.0229H114.951L117.58 53.6355H107.907L106.224 35.2276ZM61.8537 43.5374V34.0705L56.0708 43.5374H61.8537ZM71.2115 26.9177V53.5303H61.8537V49.2176H52.4959L49.8673 53.5303H38.9323L56.9119 26.9177H71.2115ZM50.0776 26.9177L32.098 53.6355H0.660034L18.6396 27.0229H50.0776V26.9177Z"
          fill="#1860D8"
        />
        <path
          d="M191.075 68.993L193.809 67.2048C195.176 66.3633 196.122 65.4166 196.648 64.5751C197.279 63.7336 197.489 62.7869 197.489 61.735C197.489 60.7883 197.279 59.9468 196.753 59.2105C196.333 58.4742 195.597 57.9482 194.755 57.5275C193.914 57.1067 192.863 57.0015 191.706 57.0015C189.814 57.0015 188.026 57.5275 186.554 58.4742V61.6298C187.08 61.1039 187.711 60.7883 188.447 60.4727C189.183 60.1572 190.024 60.052 190.76 60.052C191.706 60.052 192.442 60.2624 192.968 60.6831C193.494 61.1039 193.704 61.6298 193.704 62.3661C193.704 62.7869 193.599 63.2076 193.494 63.5232C193.283 63.9439 193.073 64.2595 192.653 64.6803C192.232 65.101 191.706 65.5218 190.97 66.0477L186.554 69.2033V71.5175H197.594V68.8878H191.075V68.993Z"
          fill="#1860D8"
        />
        <path
          d="M205.375 64.3647C205.375 71.6227 199.487 77.5132 192.232 77.5132C184.977 77.5132 179.089 71.6227 179.089 64.3647C179.089 57.1067 184.977 51.2162 192.232 51.2162C199.382 51.2162 205.375 57.1067 205.375 64.3647ZM204.849 53.2147C206.321 49.1124 207.057 44.7997 207.057 40.1714C207.057 18.3975 189.393 0.725891 167.628 0.725891C155.432 0.725891 144.497 6.30086 137.242 15.0315H142.289C148.808 8.50981 157.745 4.40747 167.628 4.40747C187.29 4.40747 203.272 20.3961 203.272 40.0662C203.272 43.6426 202.746 47.1138 201.695 50.3747C198.961 48.4813 195.596 47.3242 192.022 47.3242C187.395 47.3242 183.084 49.2176 180.035 52.268V26.9177H176.25V38.278H159.637V26.9177H155.957V53.6355H159.742V41.7492H176.355V53.6355H179.194C176.776 56.5808 175.304 60.3675 175.304 64.3647C175.304 68.0463 176.46 71.5175 178.563 74.2523C175.199 75.3042 171.519 75.9354 167.733 75.9354C157.85 75.9354 148.913 71.9382 142.394 65.4166H137.347C144.602 74.1472 155.537 79.7221 167.733 79.7221C172.465 79.7221 177.091 78.8806 181.297 77.3028C184.241 79.8273 188.026 81.2999 192.232 81.2999C201.59 81.2999 209.16 73.7264 209.16 64.3647C209.055 60.1572 207.478 56.2652 204.849 53.2147Z"
          fill="#1860D8"
        />
      </svg>
    </div>
  );
}
