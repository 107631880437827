import { Route, Routes } from "react-router";
import { EquipmentDetailsPage } from "./EquipmentDetailsPage";
import { MonitoringHomePage } from "./MonitoringHomePage";

export const MonitoringNavigator = () => {
  return (
    <Routes>
      <Route index element={<MonitoringHomePage />} />
      <Route path="card" element={<MonitoringHomePage />} />
      <Route path="map" element={<MonitoringHomePage />} />
      <Route
        path=":equipmentId/equipment-details"
        element={<EquipmentDetailsPage />}
      />
    </Routes>
  );
};
