import { CloseFilled, FitToScreen } from "@carbon/icons-react";
import {
  Button,
  ContentSwitcher,
  InlineLoading,
  Stack,
  Switch,
  Tag,
} from "@carbon/react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { CustomBreadcrumb } from "../../common/CustomBreadcrumb";
import { formatDateWithTime } from "../../common/dates";
import { ProcessFlowDiagram } from "./ProcessFlowDiagram";
import { ThingsboardDashboard } from "./ThingsboardDashboard";
import { TelemetryDashboardType } from "./enums/TelemetryDashboardType.enum";
import "./equipmentDetails.scss";
import {
  EquipmentDto,
  EquipmentTelemetryDto,
  equipmentsApi,
} from "./equipmentsApi";

export const EquipmentDetailsPage = () => {
  const [equipmentTelemetry, setEquipmentTelemetry] =
    useState<EquipmentTelemetryDto | null>();
  const [selectedView, setSelectedView] = useState("");
  const parentContainerRef: any = useRef();
  const [isFullScreen, setFullScreen] = useState<boolean>(false);
  const { equipmentId } = useParams();
  const [equipmentDetails, setEquipmentDetails] = useState<EquipmentDto | null>(
    null
  );

  useEffect(() => {
    getEquipmentDetails(equipmentId || "");
  }, []);

  const getTelemetrySummary = async (telemetryId: string) => {
    try {
      const equipmentTelemetry = await equipmentsApi.getFullEquipmentTelemetry(
        telemetryId
      );
      setEquipmentTelemetry(equipmentTelemetry);
    } catch (e) {
      setEquipmentTelemetry(null);
    }
  };

  const getEquipmentDetails = async (equipmentId: string) => {
    try {
      const equipmentDetails = await equipmentsApi.getEquipmentById(
        equipmentId
      );
      setEquipmentDetails(equipmentDetails.equipment);
      getTelemetrySummary(equipmentDetails.equipment.telemetryId);
    } catch (e) {
      setEquipmentDetails(null);
    }
  };

  const handleSwitchChange = (event: any) => {
    setSelectedView(event.name);
  };

  const getDisplaySettings = (
    equipmentTelemetry: EquipmentTelemetryDto,
    dashboardId: string
  ): any => {
    const dashboard = equipmentTelemetry.dashboards.find(
      (dashboard) => dashboard.id === dashboardId
    );

    return dashboard?.displaySettings;
  };

  const getDisplayTypeById = (
    equipmentTelemetry: EquipmentTelemetryDto,
    dashboardId: string
  ) => {
    const dashboard = equipmentTelemetry.dashboards.find(
      (dashboard) => dashboard.id === dashboardId
    );

    return dashboard?.type;
  };

  function renderStatusTags() {
    if (equipmentTelemetry && equipmentTelemetry.tags) {
      return equipmentTelemetry.tags.map((tag, index) => (
        <div key={index} className="status">
          <span className="status-type">{tag.label}</span>
          <span
            className="status-condition"
            style={{
              backgroundColor: tag.backgroundColor,
              color: tag.textColor,
            }}
          >
            {tag.formattedValue || tag.tagValue}
          </span>
        </div>
      ));
    } else {
      return <InlineLoading />;
    }
  }

  function renderContentSwitcher() {
    if (equipmentTelemetry && equipmentTelemetry.dashboards) {
      return (
        <ContentSwitcher
          onChange={handleSwitchChange}
          selectedIndex={0}
          className="content-switcher"
          size={"md"}
        >
          {equipmentTelemetry.dashboards.map((dashboard) => (
            <Switch
              key={dashboard.id}
              name={dashboard.id}
              text={dashboard.displayName}
              className={"switch-selected"}
            />
          ))}
        </ContentSwitcher>
      );
    } else {
      return null;
    }
  }

  function renderTelemetryDashboard(
    dashboardId: string,
    equipmentTelemetry: any
  ) {
    if (!equipmentTelemetry) {
      return null;
    }

    if (!dashboardId) {
      dashboardId = equipmentTelemetry.dashboards[0].id;
    }

    const dashboardType = getDisplayTypeById(equipmentTelemetry, dashboardId);

    switch (dashboardType) {
      case TelemetryDashboardType.THINGSBOARD_EMBEDDED:
        return (
          <ThingsboardDashboard
            displaySettings={getDisplaySettings(
              equipmentTelemetry,
              dashboardId
            )}
          />
        );
      case TelemetryDashboardType.PROCESS_FLOW_DIAGRAM:
        return (
          <ProcessFlowDiagram
            telemetryId={equipmentTelemetry.equipment.telemetryId}
            telemetryPointsConfig={getDisplaySettings(
              equipmentTelemetry,
              dashboardId
            )}
          />
        );
      default:
        return null;
    }
  }

  const handleFullScreen = () => {
    setFullScreen(!isFullScreen);
  };

  const breadcrumbItems = [
    { label: "Monitoring", href: "/monitoring" },
    {
      label: equipmentDetails ? equipmentDetails?.name : "Loading...",
      showLoading: !equipmentDetails || !equipmentTelemetry,
      isCurrentPage: true,
    },
  ];

  return (
    <div>
      <CustomBreadcrumb items={breadcrumbItems} />

      {equipmentTelemetry && equipmentDetails ? (
        <div>
          <Stack
            orientation="horizontal"
            className="equipment-card-container equipment-details-page"
          >
            <Stack
              orientation="horizontal"
              className="equipment-details equipment-details-container"
            >
              <div className="equipment-icon">
                <img
                  src={equipmentDetails?.assetType.iconUrl}
                  alt="Equipment Icon"
                />
              </div>
              <Stack className="equipment-details">
                <div className="equipment-name">{equipmentDetails?.name}</div>
                <div className="equipment-type">
                  {equipmentDetails?.assetType.name}
                </div>
                <Tag size="sm">
                  Last update:{" "}
                  {formatDateWithTime(
                    equipmentTelemetry?.tags[0].lastUpdateTs || ""
                  )}
                </Tag>
              </Stack>
            </Stack>

            <div className="status-container status-container-scrollable equipment-details-container">
              {renderStatusTags()}
            </div>
          </Stack>

          <div className="equipment-details-switcher">
            {renderContentSwitcher()}
          </div>

          <div
            className={
              isFullScreen ? "fullscreen-switcher-content" : "switcher-content"
            }
            ref={parentContainerRef}
          >
            <div
              className={
                isFullScreen
                  ? "collapse-fullscreen-button"
                  : "full-screen-button"
              }
            >
              <Button onClick={handleFullScreen} kind="ghost">
                {isFullScreen ? <CloseFilled /> : <FitToScreen />}
              </Button>
            </div>

            {renderTelemetryDashboard(selectedView, equipmentTelemetry)}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
