import { Loading, Stack } from "@carbon/react";
import { useEffect, useState } from "react";
import { AppConfig } from "../../appConfig";
import {
  CREDENTIALS_SETUP_TIME,
  THINGSBOARD_EMBED_IFRAME_ID,
  THINGSBOARD_LOAD_TIME,
} from "./constants/dashboardConstants";

export interface EmbeddedThingsboardDashboardDisplaySettingsDto {
  dashboardId: string;
  externalUrl: string;
  viewerCredentials: string;
}

export interface ThingsboardDashboardProps {
  displaySettings?: EmbeddedThingsboardDashboardDisplaySettingsDto;
}

export const ThingsboardDashboard = ({
  displaySettings,
}: ThingsboardDashboardProps) => {
  if (!displaySettings) {
    return <div>Display Settings not available.</div>;
  }

  const [loadingTb, setLoadingTb] = useState(true);
  const { externalUrl, viewerCredentials } = displaySettings;

  useEffect(() => {
    const iframe = document.getElementById(
      THINGSBOARD_EMBED_IFRAME_ID
    ) as HTMLIFrameElement;
    const targetOrigin = `${AppConfig.telemetryUrl}`;

    iframe.onload = () => {
      setTimeout(() => {
        sendCredentialsToTB(iframe, targetOrigin);
      }, THINGSBOARD_LOAD_TIME);
    };

    setTimeout(() => {
      setLoadingTb(false);
    }, CREDENTIALS_SETUP_TIME);
  }, []);

  const sendCredentialsToTB = (
    iframe: HTMLIFrameElement,
    targetOrigin: string
  ) => {
    const decodedCredentials = atob(viewerCredentials);
    const [username, password] = decodedCredentials.split(":");

    const credentials = {
      username: username,
      password: password,
    };

    iframe.contentWindow?.postMessage(credentials, targetOrigin);
  };

  return (
    <>
      <Stack>{loadingTb ? <Loading withOverlay={false} /> : null}</Stack>

      <iframe
        id={THINGSBOARD_EMBED_IFRAME_ID}
        src={externalUrl}
        loading="eager"
        style={{
          position: "absolute",
          width: "calc(100% + 255px)",
          height: "calc(100% + 120px)",
          marginTop: "-128px",
          marginLeft: "-255px",
          display: loadingTb ? "none" : "block",
        }}
      ></iframe>
    </>
  );
};
