import { Tooltip } from "@carbon/react";
import { useEffect, useState } from "react";

export interface DiagramValueProps {
  heightScalingFactor: number;
  widthScalingFactor: number;
  top: number;
  left: number;
  tooltipLabel: string;
  tagId: string;
  tag: {
    backgroundColor: string;
    textColor: string;
    tagValue: string;
  };
}

export const DiagramValues = ({
  heightScalingFactor,
  widthScalingFactor,
  top,
  left,
  tooltipLabel,
  tagId,
  tag,
}: DiagramValueProps) => {
  const [tempBackgroundColor, setTempBackgroundColor] = useState<string>("");

  useEffect(() => {
    setTempBackgroundColor("green");
    const timeoutId = setTimeout(() => {
      setTempBackgroundColor(tag.backgroundColor);
    }, 150);
    return () => clearTimeout(timeoutId);
  }, [tag.tagValue]);

  return (
    <p
      className="text-sans"
      dir="auto"
      key={`text_${tagId}`}
      style={{
        position: "absolute",
        top,
        left,
        height: 60 / heightScalingFactor,
        width: 165 / widthScalingFactor,
        zIndex: 0,
        backgroundColor: tempBackgroundColor || tag.backgroundColor,
        border: "1px solid black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 24 / widthScalingFactor,
        transition: "background-color 0.5s ease",
      }}
    >
      <Tooltip label={tooltipLabel} key={tagId}>
        <code className="text-sans" dir="center" color={tag.textColor}>
          {tag.tagValue}
        </code>
      </Tooltip>
    </p>
  );
};
