import { useEffect } from "react";
import { EquipmentCard } from "./EquipmentCard";
import "./equipmentListCardView.scss";
import {
  EquipmentDto,
  EquipmentListDto,
  EquipmentSiteDto,
  EquipmentTypeDto,
} from "./equipmentsApi";

export interface EquipmentListCardViewProps {
  equipmentList: EquipmentListDto;
  refreshFlag: boolean;
}

export const EquipmentListCardView = ({
  equipmentList,
  refreshFlag,
}: EquipmentListCardViewProps) => {
  const findSiteById = (
    sites: EquipmentSiteDto[],
    siteId: string
  ): EquipmentSiteDto | undefined => {
    return sites.find((site) => site.id === siteId);
  };

  const findEquipmentTypeById = (
    equipmentTypes: EquipmentTypeDto[],
    typeId: string
  ): EquipmentTypeDto | undefined => {
    return equipmentTypes.find((type) => type.id === typeId);
  };

  useEffect(() => {}, [refreshFlag]);

  const renderEquipmentCards = (
    equipmentList: EquipmentListDto,
    refreshFlag: boolean
  ) => {
    const { equipment, sites, equipmentTypes } = equipmentList;

    return equipment.map((equipment: EquipmentDto) => {
      const site = findSiteById(sites, equipment.siteId);
      const equipmentType = findEquipmentTypeById(
        equipmentTypes,
        equipment.assetTypeId
      );

      return (
        <div key={equipment.id} className="card-column">
          <EquipmentCard
            equipment={equipment}
            site={site}
            equipmentType={equipmentType}
            refreshFlag={refreshFlag}
          />
        </div>
      );
    });
  };

  return (
    <div className="card-view">
      {renderEquipmentCards(equipmentList, refreshFlag)}
    </div>
  );
};

export default EquipmentListCardView;
