import { Outlet } from "react-router";
import { AppConfig } from "../../appConfig";
import { AppNavbar } from "../NavBar";
import "./style-file.scss";

const AppFooter = () => {
  return (
    <div className="footer">
      <div className="inner">
        <span>
          {"Copyright © 2024 Protium OneH2 All rights reserved"}{" "}
          {`v${AppConfig.appVersion}`}
        </span>
      </div>
    </div>
  );
};

export const AppBody = () => {
  return (
    <div className="app-body">
      <Outlet />
    </div>
  );
};

const AppLayout = () => {
  return (
    <div style={{ margin: 0 }}>
      <AppNavbar />
      <AppBody />
      <AppFooter />
    </div>
  );
};

export default AppLayout;
