import { Button, InlineLoading, Stack, Tag } from "@carbon/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { formatDateWithTime } from "../../common/dates";
import "./equipmentCard.scss";
import {
  EquipmentDto,
  EquipmentSiteDto,
  EquipmentTelemetrySummaryDto,
  EquipmentTypeDto,
  equipmentsApi,
} from "./equipmentsApi";

interface EquipmentCardProps {
  equipment: EquipmentDto;
  equipmentType?: EquipmentTypeDto;
  site?: EquipmentSiteDto;
  refreshFlag: boolean;
}

export const EquipmentCard = (props: EquipmentCardProps) => {
  const { equipment, equipmentType, site, refreshFlag } = props;
  const navigate = useNavigate();
  const [telemetrySummary, setTelemetrySummary] =
    useState<EquipmentTelemetrySummaryDto | null>();

  useEffect(() => {
    getTelemetrySummary();
  }, [refreshFlag]);

  const getTelemetrySummary = async () => {
    try {
      if (!equipment.telemetryId) {
        setTelemetrySummary(null);
        return;
      }

      const summary = await equipmentsApi.getEquipmentTelemetrySummary(
        equipment.telemetryId
      );
      setTelemetrySummary(summary);
    } catch (e) {
      setTelemetrySummary(null);
    }
  };

  const renderStatusTags = () => {
    if (telemetrySummary && telemetrySummary.tags) {
      return (
        <div className="status-container-scrollable">
          {telemetrySummary.tags.map((tag, index) => (
            <div key={index} className="status">
              <span className="status-type">{tag.label}</span>
              <span
                className="status-condition"
                style={{
                  backgroundColor: tag.backgroundColor,
                  color: tag.textColor,
                }}
              >
                {tag.formattedValue || tag.tagValue}
              </span>
            </div>
          ))}
        </div>
      );
    } else {
      return <div className="status-not-available">Not available</div>;
    }
  };

  return (
    <Stack className="equipment-card-container">
      <Stack orientation="horizontal">
        <div className="equipment-icon">
          <img src={equipment.iconUrl} alt="Equipment Icon" />
        </div>
        <Stack className="equipment-details">
          <div className="equipment-name">{equipment.name}</div>
          <div className="equipment-type">{equipmentType?.name}</div>
          <Tag size="sm">
            Last update:{" "}
            {telemetrySummary?.lastUpdatedAt
              ? formatDateWithTime(telemetrySummary?.tags[0].lastUpdateTs)
              : "Not available"}
          </Tag>
        </Stack>
      </Stack>

      <div className="status-container">
        {telemetrySummary ? renderStatusTags() : <InlineLoading />}
      </div>

      <Stack orientation="horizontal" className="card-footer">
        <Tag type={"blue"}>{site?.displayName}</Tag>
        <Button
          kind="ghost"
          onClick={() =>
            navigate(`${equipment.id}/equipment-details`, {
              state: {
                telemetryId: equipment.telemetryId,
                equipmentId: equipment.id,
                equipmentName: equipment.name,
                iconUrl: equipment.iconUrl,
                equipmentType: equipmentType?.name,
              },
            })
          }
        >
          More details
        </Button>
      </Stack>
    </Stack>
  );
};

export default EquipmentCard;
