import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import { TileLayer } from "react-leaflet";
import { MapContainer } from "react-leaflet/MapContainer";
import CustomMarker from "./CustomMarker";
import "./equipmentListMapView.scss";
import {
  EquipmentDto,
  EquipmentListDto,
  EquipmentSiteDto,
  EquipmentTypeDto,
} from "./equipmentsApi";

export interface EquipmentListMapViewProps {
  equipmentList: EquipmentListDto;
}

const DefaultIcon = new L.Icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [175, 0],
  shadowSize: [41, 41],
  shadowAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

export const EquipmentListMapView = ({
  equipmentList,
}: EquipmentListMapViewProps) => {
  const findSiteById = (
    sites: EquipmentSiteDto[],
    siteId: string
  ): EquipmentSiteDto | undefined => {
    return sites.find((site) => site.id === siteId);
  };

  const findEquipmentTypeById = (
    equipmentTypes: EquipmentTypeDto[],
    typeId: string
  ): EquipmentTypeDto | undefined => {
    return equipmentTypes.find((type) => type.id === typeId);
  };

  const renderCustomMarkers = (equipmentList: EquipmentListDto) => {
    const { equipment, sites, equipmentTypes } = equipmentList;

    return equipment.map((equipment: EquipmentDto) => {
      const site = findSiteById(sites, equipment.siteId);
      const equipmentType = findEquipmentTypeById(
        equipmentTypes,
        equipment.assetTypeId
      );

      if (!site) {
        return null;
      }

      return (
        <CustomMarker
          key={equipment.id}
          data={{
            equipmentType: equipmentType,
            site: site,
            equipment: equipment,
          }}
        />
      );
    });
  };

  return (
    <MapContainer
      center={[37.0902, -95.7129]}
      zoom={4}
      maxZoom={20}
      minZoom={4}
      style={{ height: "calc(100vh - 360px) ", width: "100%" }}
      dragging={true}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {renderCustomMarkers(equipmentList)}
    </MapContainer>
  );
};
